import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { MutationFetchFunction } from 'common/helpers/common';
import { ApiError, ApiResult } from 'common/repositories/common.model';
import { queryClient } from 'common/repositories/query-client';

import {
  GuestePassengerModel,
  GuestPassengerMutationDelete,
  GuestPassengerMutationInput,
  GuestPassengerMutationUpdate,
  GuestVehicleModel,
  GuestVehicleMutationDelete,
  GuestVehicleMutationInput,
  GuestVehicleMutationUpdate,
} from './model';

export function useCreateGuestPassenger(
  options?: UseMutationOptions<
    ApiResult<GuestePassengerModel>,
    ApiError,
    GuestPassengerMutationInput
  >,
): UseMutationResult<
  ApiResult<GuestePassengerModel>,
  ApiError,
  GuestPassengerMutationInput
> {
  return useMutation<
    ApiResult<GuestePassengerModel>,
    ApiError,
    GuestPassengerMutationInput
  >(
    async function (body) {
      return MutationFetchFunction({
        method: 'POST',
        url: 'passenger-guests',
        body,
        classType: GuestePassengerModel,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(['passenger-guests']);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useUpdateGuestPassenger(
  options?: UseMutationOptions<
    ApiResult<GuestePassengerModel>,
    ApiError,
    GuestPassengerMutationUpdate
  >,
): UseMutationResult<
  ApiResult<GuestePassengerModel>,
  ApiError,
  GuestPassengerMutationUpdate
> {
  return useMutation<
    ApiResult<GuestePassengerModel>,
    ApiError,
    GuestPassengerMutationUpdate
  >(
    async function (body) {
      return MutationFetchFunction({
        method: 'PUT',
        url: `passenger-guests/${body.id}`,
        body: body.body,
        classType: GuestePassengerModel,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(['passenger-guests']);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useDeleteGuestPassenger(
  options?: UseMutationOptions<
    { message: string },
    ApiError,
    GuestPassengerMutationDelete
  >,
): UseMutationResult<
  { message: string },
  ApiError,
  GuestPassengerMutationDelete
> {
  return useMutation<
    { message: string },
    ApiError,
    GuestPassengerMutationDelete
  >(
    async function (body) {
      return MutationFetchFunction({
        method: 'DELETE',
        url: `passenger-guests/${body.id}`,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(['passenger-guests']);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useCreateGuestVehicle(
  options?: UseMutationOptions<
    ApiResult<GuestVehicleModel>,
    ApiError,
    GuestVehicleMutationInput
  >,
): UseMutationResult<
  ApiResult<GuestVehicleModel>,
  ApiError,
  GuestVehicleMutationInput
> {
  return useMutation<
    ApiResult<GuestVehicleModel>,
    ApiError,
    GuestVehicleMutationInput
  >(
    async function (body) {
      return MutationFetchFunction({
        method: 'POST',
        url: 'vehicle-guests',
        body,
        classType: GuestVehicleModel,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(['vehicle-guests']);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useUpdateGuestVehicle(
  options?: UseMutationOptions<
    ApiResult<GuestVehicleModel>,
    ApiError,
    GuestVehicleMutationUpdate
  >,
): UseMutationResult<
  ApiResult<GuestVehicleModel>,
  ApiError,
  GuestVehicleMutationUpdate
> {
  return useMutation<
    ApiResult<GuestVehicleModel>,
    ApiError,
    GuestVehicleMutationUpdate
  >(
    async function (body) {
      return MutationFetchFunction({
        method: 'PUT',
        url: `vehicle-guests/${body.id}`,
        body: body.body,
        classType: GuestVehicleModel,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(['vehicle-guests']);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}

export function useDeleteGuestVehicle(
  options?: UseMutationOptions<
    { message: string },
    ApiError,
    GuestVehicleMutationDelete
  >,
): UseMutationResult<
  { message: string },
  ApiError,
  GuestVehicleMutationDelete
> {
  return useMutation<{ message: string }, ApiError, GuestVehicleMutationDelete>(
    async function (body) {
      return MutationFetchFunction({
        method: 'DELETE',
        url: `vehicle-guests/${body.id}`,
      });
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.refetchQueries(['vehicle-guests']);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}
