import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';
import { ApiError, ApiResult } from 'common/repositories/common.model';

import {
  getGuestPassengerInput,
  getGuestPassengersInput,
  getGuestVehicleInput,
  getGuestVehiclesInput,
  GuestePassengerModel,
  GuestPassengerLiteModel,
  GuestVehicleLiteModel,
  GuestVehicleModel,
} from './model';

export function useGetGuestPassengers(
  input?: getGuestPassengersInput,
  options?: UseQueryOptions<ApiResult<GuestPassengerLiteModel[]>, ApiError>,
): UseQueryResult<ApiResult<GuestPassengerLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['passenger-guests', input?.params],
      () =>
        QueryFetchFunction({
          url: 'passenger-guests',
          params: input?.params,
        }),
      { staleTime: Infinity, cacheTime: Infinity, ...options },
    ),
    GuestPassengerLiteModel,
  );
}

export function useGetGuestPassenger(
  input?: getGuestPassengerInput,
  options?: UseQueryOptions<ApiResult<GuestePassengerModel>, ApiError>,
): UseQueryResult<ApiResult<GuestePassengerModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['passenger-guests', input?.id],
      () => QueryFetchFunction({ url: `passenger-guests/${input?.id}` }),
      { staleTime: Infinity, cacheTime: Infinity, ...options },
    ),
    GuestePassengerModel,
  );
}

export function useGetGuestVehicles(
  input?: getGuestVehiclesInput,
  options?: UseQueryOptions<ApiResult<GuestVehicleLiteModel[]>, ApiError>,
): UseQueryResult<ApiResult<GuestVehicleLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['vehicle-guests', input?.params],
      () =>
        QueryFetchFunction({
          url: 'vehicle-guests',
          params: input?.params,
        }),
      { staleTime: Infinity, cacheTime: Infinity, ...options },
    ),
    GuestVehicleLiteModel,
  );
}

export function useGetGuestVehicle(
  input?: getGuestVehicleInput,
  options?: UseQueryOptions<ApiResult<GuestVehicleModel>, ApiError>,
): UseQueryResult<ApiResult<GuestVehicleModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['vehicle-guests', input?.id],
      () => QueryFetchFunction({ url: `vehicle-guests/${input?.id}` }),
      { staleTime: Infinity, cacheTime: Infinity, ...options },
    ),
    GuestVehicleModel,
  );
}

export function getGuestVehiclesKey(input?: getGuestVehiclesInput) {
  return ['vehicle-guests', input?.params];
}

export function getGuestVehicleKey(input?: getGuestVehicleInput) {
  return ['vehicle-guests', input?.id];
}

export function getGuestPassengersKey(input?: getGuestPassengersInput) {
  return ['passenger-guests', input?.params];
}

export function getGuestPassengerKey(input?: getGuestPassengerInput) {
  return ['passenger-guests', input?.id];
}
