import { useGetMe } from 'api-hooks/auth/query';
import { getCredential } from 'common/credential-storage';
import React from 'react';

export default function useGetMeQuery() {
  const queryGetMe = useGetMe();
  const userId = queryGetMe.data?.data?.id;

  const isAuthenticating = React.useMemo(() => {
    if (typeof getCredential()?.accessToken === 'string')
      return queryGetMe.isLoading;
    return false;
  }, [queryGetMe.isLoading]);

  const isAuthenticated = React.useMemo(() => {
    if (typeof userId === 'string') return true;
    return false;
  }, [userId]);

  return {
    ...queryGetMe,
    isAuthenticating,
    isAuthenticated,
  };
}
