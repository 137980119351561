import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';
import { ApiError, ApiResult } from 'common/repositories/common.model';

import {
  getVoyageArrivalsInput,
  getVoyageDepartsInput,
  getVoyageDetailsInput,
  getVoyageShowTempDetail,
  PassengerTypeModel,
  VehicleTypeModel,
  VoyagerDetailModel,
  VoyageSearchItemModel,
  VoyageSearchModel,
  VoyagesPortModel,
} from './model';

export function useGetPassengerTypes(
  options?: UseQueryOptions<ApiResult<PassengerTypeModel[]>, ApiError>,
): UseQueryResult<ApiResult<PassengerTypeModel[]>, ApiError> {
  return QueryTransformer(
    useQuery<ApiResult<PassengerTypeModel[]>, ApiError>(
      ['getPassengerTypes'],
      () => QueryFetchFunction({ url: 'search/type/passengers' }),
      { staleTime: Infinity, cacheTime: Infinity, ...options },
    ),
    PassengerTypeModel,
  );
}

export function useGetVehicleTypes(
  options?: UseQueryOptions<ApiResult<VehicleTypeModel[]>, ApiError>,
): UseQueryResult<ApiResult<VehicleTypeModel[]>, ApiError> {
  return QueryTransformer(
    useQuery<ApiResult<VehicleTypeModel[]>, ApiError>(
      ['getVehicleTypes'],
      () => QueryFetchFunction({ url: 'search/type/vehicles' }),
      { staleTime: Infinity, cacheTime: Infinity, ...options },
    ),
    VehicleTypeModel,
  );
}

export function useGetVoyageDepartures(
  input?: getVoyageDepartsInput,
  options?: UseQueryOptions<ApiResult<VoyagesPortModel[]>, ApiError>,
): UseQueryResult<ApiResult<VoyagesPortModel[]>, ApiError> {
  return QueryTransformer(
    useQuery<ApiResult<VoyagesPortModel[]>, ApiError>(
      ['getVoyageDepartures', input?.params],
      () =>
        QueryFetchFunction({
          url: 'search/voyages/departures',
          params: input?.params,
        }),
      { staleTime: Infinity, cacheTime: Infinity, ...options },
    ),
    VoyagesPortModel,
  );
}

export function useGetVoyageArrivals(
  input?: getVoyageArrivalsInput,
  options?: UseQueryOptions<ApiResult<VoyagesPortModel[]>, ApiError>,
): UseQueryResult<ApiResult<VoyagesPortModel[]>, ApiError> {
  return QueryTransformer(
    useQuery<ApiResult<VoyagesPortModel[]>, ApiError>(
      ['getVoyageArrivals', input?.params],
      () =>
        QueryFetchFunction({
          url: 'search/voyages/arrivals',
          params: input?.params,
        }),
      { staleTime: Infinity, cacheTime: Infinity, ...options },
    ),
    VoyagesPortModel,
  );
}

export function useGetVoyageSearchs(
  input?: any,
  options?: UseQueryOptions<ApiResult<VoyageSearchModel>, ApiError>,
): UseQueryResult<ApiResult<VoyageSearchModel>, ApiError> {
  return QueryTransformer(
    useQuery<ApiResult<VoyageSearchModel>, ApiError>(
      ['getVoyageArrivals', input?.params],
      () =>
        QueryFetchFunction({
          url: 'search/voyages',
          params: input?.params,
        }),
      { staleTime: Infinity, cacheTime: Infinity, ...options },
    ),
    VoyageSearchModel,
  );
}

export function useGetVoyageDetails(
  input?: getVoyageDetailsInput,
  options?: UseQueryOptions<ApiResult<VoyagerDetailModel>, ApiError>,
): UseQueryResult<ApiResult<VoyagerDetailModel>, ApiError> {
  return QueryTransformer(
    useQuery<ApiResult<VoyagerDetailModel>, ApiError>(
      ['getVoyageDetails', input],
      () =>
        QueryFetchFunction({
          url: `voyages/${input?.voyageCode}/detail`,
          params: input?.params,
        }),
      { staleTime: Infinity, cacheTime: Infinity, ...options },
    ),
    VoyagerDetailModel,
  );
}

export function useGetVoyageShowTempDetail(
  input?: getVoyageShowTempDetail,
  options?: UseQueryOptions<ApiResult<VoyageSearchItemModel>, ApiError>,
): UseQueryResult<ApiResult<VoyageSearchItemModel>, ApiError> {
  return QueryTransformer(
    useQuery<ApiResult<VoyageSearchItemModel>, ApiError>(
      ['getVoyageShowTempDetail', input],
      () =>
        QueryFetchFunction({
          url: `search/voyages/${input?.voyageCode}?${input?.params}`,
        }),
      options,
    ),
    VoyageSearchItemModel,
  );
}
