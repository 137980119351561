import Stack from 'components/commons/stack';
import Button from 'components/elements/button';
import { addHours } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import { ImagePopup } from './style';

interface Props {
  onClose: () => void;
}

const imageList = [
  { alt: 'wjl-lines/policy-4-1.webp', src: '/assets/policy-4-1.webp' },
  { alt: 'wjl-lines/policy-3.webp', src: '/assets/policy-3.webp' },
  { alt: 'wjl-lines/policy-2.webp', src: '/assets/policy-2.webp' },
  { alt: 'wjl-lines/policy.png', src: '/assets/policy.webp' },
];

export default function PolicyDialog({ onClose }: Props) {
  const { t } = useTranslation();
  const [state, setState] = React.useState(0);

  const onClick = React.useCallback(() => {
    try {
      if (state < imageList.length - 1) {
        setState((prev) => prev + 1);
      } else {
        localStorage.setItem(
          'expired-dialog',
          addHours(new Date(), 1).toISOString(),
        );
        onClose();
      }
    } catch (e) {
    } finally {
    }
  }, [state, onClose]);

  return (
    <Stack
      gap="lg"
      justify="center"
      rootCss={{
        height: '100%',
        width: '100%',
      }}
    >
      <Stack.Item css={{ width: '100%' }}>
        <ImagePopup src={imageList[state].src} alt={imageList[state].alt} />
      </Stack.Item>
      <Stack.Item css={{ width: '100%' }}>
        <Button
          variant="primary"
          onClick={onClick}
          css={{ alignSelf: 'center' }}
        >
          {t(
            `common:${
              state !== imageList.length - 1 ? 'continue' : 'understand'
            }`,
          )}
        </Button>
      </Stack.Item>
    </Stack>
  );
}
