export const LanguageKey = 'wjl-language';

export function getLanguage() {
  if (typeof window === 'undefined') return;
  const result = localStorage.getItem(LanguageKey);

  if (!result) return;
  return result;
}

export function setLanguage(language: string) {
  if (typeof window === 'undefined') return;
  localStorage.setItem(LanguageKey, language);
}

export function clearLanguage() {
  localStorage.removeItem(LanguageKey);
}
