import { TokenResultModel } from 'api-hooks/auth/model';
import { jwtDecode } from 'jwt-decode';
export const CredentialStorageKey = 'credential';

export function getCredential() {
  if (typeof window === 'undefined') return;
  const response = localStorage.getItem(CredentialStorageKey);

  return response ? (JSON.parse(response) as TokenResultModel) : undefined;
}

export function setCredential(result: TokenResultModel) {
  localStorage.setItem(CredentialStorageKey, JSON.stringify(result));
}

export function isAccessTokenExpired(
  // 7 hours
  minutes = 60 * 7,
) {
  if (typeof window === 'undefined') return;

  const accessToken = getCredential()?.accessToken;
  if (!accessToken) return;

  const exp = jwtDecode(accessToken).exp;
  if (!exp) return;
  const now = new Date();
  const tolerence = minutes * 60 * 1000;
  const isExpired = exp * 1000 - tolerence < now.getTime();
  return isExpired;
}

export function getRefreshToken() {
  const refreshToken = getCredential()?.refreshToken;
  return refreshToken;
}

export function getAccessToken() {
  const accessToken = getCredential()?.accessToken;
  return accessToken;
}

export function clearCredential() {
  if (typeof window === 'undefined') return;
  localStorage.removeItem(CredentialStorageKey);
}
