import { useRefreshToken } from 'api-hooks/auth/mutation';
import {
  clearCredential,
  getRefreshToken,
  isAccessTokenExpired,
  setCredential,
} from 'common/credential-storage';
import { queryClient } from 'common/repositories/query-client';
import React from 'react';

export default function RefreshTokenHandler() {
  const firstTime = React.useRef(false);
  const { mutateAsync, isLoading } = useRefreshToken();

  const onRefreshToken = React.useCallback(async () => {
    if (isLoading) return;
    const refreshToken = getRefreshToken();
    if (!refreshToken) return;
    const result = await mutateAsync({ refreshToken });
    setCredential(result.data);
  }, [isLoading, mutateAsync]);

  React.useEffect(() => {
    if (firstTime.current) return;
    const interval = setInterval(async () => {
      const isTokenExpired = isAccessTokenExpired();
      if (!isTokenExpired) return;
      try {
        await onRefreshToken();
      } catch (e) {
        const errorCodes = [400, 401, 403, 404];
        if (errorCodes.includes(e?.statusCode)) {
          clearCredential();
          queryClient.clear();
          queryClient.invalidateQueries();
        }
      } finally {
        queryClient.clear();
        queryClient.invalidateQueries();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [onRefreshToken]);

  return null;
}
