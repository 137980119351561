import { globalCss } from './styles';

export const resetStyles = globalCss({
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    overscrollBehavior: 'none',
  },
  body: {
    margin: '0',
    fontFamily:
      "Montserrat, 'Source Sans Pro',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    color: '$defaultFont',
    lineHeight: 1,
    position: 'relative',
  },
  svg: { verticalAlign: 'middle' },
  // 'html, body, #root, #__next, .theme-0': {
  //   height: '100%',
  // },
  '#__next': {
    position: 'relative',
    zIndex: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  img: { verticalAlign: 'middle', maxWidth: '100%' },
  a: { textDecoration: 'none' },
  button: {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    boxSizing: 'border-box',
    display: 'inline-flex',
    font: 'inherit',
    justifyContent: 'center',
    lineHeight: 1,
    margin: '0',
    outline: 'none',
    padding: '0',
    textAlign: 'inherit',
    textDecoration: 'none',
    userSelect: 'none',
    verticalAlign: 'middle',
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  },
  form: {
    width: '100%',
  },
  'button::before, button::after': { boxSizing: 'border-box' },
  'h1, h2, h3, h4, h5, h6, ol, li': { margin: '0' },
  'ol, li': {
    padding: 0,
  },
});
