import { Expose, Type } from 'class-transformer';
import { ImageClass } from 'common/repositories/common.model';

// Model

export class PassengerTypeModel {
  id: string;
  name: string;

  @Expose({ name: 'from_age' })
  fromAge: string | null;

  @Expose({ name: 'to_age' })
  toAge: string | null;
}

export class VehicleTypeModel {
  id: string;
  name: string;
  description: string;
  accomodation: number;
  code: string;
  order: number;

  @Expose({ name: 'lane_meter' })
  @Type(() => Number)
  laneMeter: number;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'max_passenger' })
  @Type(() => Number)
  maxPassenger: number;
}

export class VoyagesPortModel {
  id: string;
  code: string | null;
  name: string;

  @Expose({ name: 'city_name' })
  cityName: string;
}

export class VoyageFacilityAvailableModel {
  seat: number;
  bed: number;
  room: number;
  @Expose({ name: 'non_seated' })
  nonSeated: number;
}

export class VoyageSearchItemModel {
  @Expose({ name: 'voyage_code' })
  voyageCode: string;
  @Expose({ name: 'departure_at' })
  @Type(() => Date)
  departureAt: Date;

  @Expose({ name: 'departure_tz' })
  departureTz: string;

  @Expose({ name: 'departure_tz_offset' })
  departureTzOffset: string;

  @Expose({ name: 'departure_date' })
  departureDate: string;

  @Expose({ name: 'departure_time' })
  departureTime: string;

  @Expose({ name: 'from_harbor' })
  fromHarbor: string;

  @Expose({ name: 'from_city' })
  fromCity: string;

  @Expose({ name: 'arrived_at' })
  @Type(() => Date)
  arrivedAt: Date;

  @Expose({ name: 'arrived_tz' })
  arrivedTz: string;

  @Expose({ name: 'arrived_tz_offset' })
  arrivedTzOffset: string;

  @Expose({ name: 'arrival_date' })
  arrivalDate: string;

  @Expose({ name: 'arrival_time' })
  arrivalTime: string;

  @Expose({ name: 'to_harbor' })
  toHarbor: string;

  @Expose({ name: 'to_city' })
  toCity: string;

  @Expose({ name: 'ship_name' })
  shipName: string;

  @Expose({ name: 'passengers_summary' })
  passengersSummary: string;

  @Expose({ name: 'vehicles_summary' })
  vehicleSummary: string;

  @Expose({ name: 'price_total' })
  priceTotal: number;

  @Expose({ name: 'facility_available' })
  @Type(() => VoyageFacilityAvailableModel)
  facilityAvailable: VoyageFacilityAvailableModel | null;
}

export class VoyageSearchParamsModel {
  date: string;
  @Expose({ name: 'from_harbor' })
  fromHarbor: string;

  @Expose({ name: 'to_harbor' })
  toHarbor: string;

  @Expose({ name: 'passengers_count' })
  passengersCount: number;

  @Expose({ name: 'vehicles_count' })
  vehiclesCount: number;
}

export class VoyageSearchModel {
  @Expose({ name: 'alt_result' })
  altResult: string;

  @Expose({ name: 'main_result' })
  mainResult: string;

  // result: string;

  @Expose({ name: 'search_params' })
  @Type(() => VoyageSearchParamsModel)
  searchParams: VoyageSearchParamsModel;

  @Expose({ name: 'main_voyages' })
  @Type(() => VoyageSearchItemModel)
  mainVoyages: VoyageSearchItemModel[];

  @Expose({ name: 'alt_voyages' })
  @Type(() => VoyageSearchItemModel)
  altVoyages: VoyageSearchItemModel[];

  // voyages: VoyageSearchItemModel[];
}

export class VoyagerPassengerTypeModel {
  id: string;
  name: string;
  price: string;

  @Expose({ name: 'insurance_price' })
  insurancePrice: string;

  @Expose({ name: 'is_insurance_required' })
  isInsuranceRequired: boolean;
}

export class VoyagerVehicleTypeModel extends VoyagerPassengerTypeModel {
  content: string;
  code: string;
}

export class VoyagerFacilityTypeModel extends VoyagerPassengerTypeModel {
  variant: string;
  capacity: number;

  @Type(() => ImageClass)
  images: ImageClass[] | null;

  @Expose({ name: 'bed_qty' })
  bedQty: number;

  @Expose({ name: 'available_qty' })
  availableQty: number;
}

export class VoyagerDetailModel {
  @Expose({ name: 'passenger_types' })
  @Type(() => VoyagerPassengerTypeModel)
  passengerTypes: VoyagerPassengerTypeModel[];

  @Expose({ name: 'vehicle_types' })
  @Type(() => VoyagerVehicleTypeModel)
  vehicleTypes: VoyagerVehicleTypeModel[];

  @Expose({ name: 'voyage_facility_types' })
  @Type(() => VoyagerFacilityTypeModel)
  voyageFacilityTypes: VoyagerFacilityTypeModel[];
}

export class VoyagePriceSummaryItem {
  id: string;
  name: string;
  qty: number;
  price: string;
  subtotal: number;

  @Expose({ name: 'voyage_facility_type_id' })
  voyageFacilityTypeId: string;
}

export class VoyagePriceSummaryDetail {
  @Type(() => VoyagePriceSummaryItem)
  passengers: VoyagePriceSummaryItem[];

  @Type(() => VoyagePriceSummaryItem)
  vehicles: VoyagePriceSummaryItem[];

  @Type(() => VoyagePriceSummaryItem)
  facilities: VoyagePriceSummaryItem[];

  @Expose({ name: 'total_price' })
  totalPrice: number;

  @Expose({ name: 'admin_fee' })
  adminFee: number;

  @Expose({ name: 'harbor_fee' })
  harborFee: number;

  @Expose({ name: 'vehicle_accommodation' })
  vehicleAccomodation: number;
}

// Input

export class getVoyageArrivalsInput {
  params?: {
    d: string;
  };
}

export class getVoyageDepartsInput {
  params?: {
    a: string;
  };
}

export class getVoyageSearchsInput {
  params?: {
    [key: string]: string;
  };
}

export class getVoyageDetailsInput {
  voyageCode: string;
  params: {
    type?: string;
  };
}

export class getVoyagePriceSummaryInput {
  voyageCode: string;
}

export class getVoyageShowTempDetail {
  voyageCode: string;
  params: string;
}

export class VoyagePriceSummaryInput {
  voyageCode: string;
  body: {
    passengers: { passengerTypeId: string; qty: number }[];
    vehicles: { vehicleTypeId: string; qty: number }[];
    // vehiclePassengers: { [key: string]: number };
    facilities: { voyageFacilityTypeId: string; qty: number }[];
  };
}
