import { useGetVoyageDepartures } from 'api-hooks/voyage/query';
import { CruiseIcon } from 'assets/images/svg';
import IconBox from 'components/commons/icon-box';
import Input from 'components/elements/field';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { Oval } from 'react-loader-spinner';
import color from 'styles/color';

interface Props {
  name: string;
  required?: boolean;
  disabled?: boolean;
  arrivalCode?: string;
}

export default function DepartureVoyageSelectInput(props: Props) {
  const { t } = useTranslation();
  const { data, isFetching } = useGetVoyageDepartures({
    params: { a: props.arrivalCode || '' },
  });
  const options = React.useMemo(() => {
    if (data?.data) {
      return data.data.map((item) => ({ value: item.code, label: item.name }));
    }
    return [];
  }, [data?.data]);

  return (
    <Input
      {...props}
      type="select"
      label={t('common:from')}
      placeholder={t('common:select_origin_port')}
      options={options}
      onRenderLeft={
        isFetching ? (
          <IconBox css={{ pl: 16 }}>
            <Oval
              color={color.primary}
              secondaryColor={color.primary5}
              width={24}
              height={24}
            />
          </IconBox>
        ) : (
          <IconBox css={{ ml: 16 }} tabIndex={-1}>
            <CruiseIcon size={24} />
          </IconBox>
        )
      }
    />
  );
}
