import { VehicleTypeModel } from 'api-hooks/voyage/model';
import { Expose, Type } from 'class-transformer';

export class GuestePassengerModel {
  id: string;
  name: string;
  type: string;
  gender: string;

  @Type(() => Number)
  age: number;

  @Expose({ name: 'identifier_code' })
  identifierCode: string | null;

  @Expose({ name: 'identifier_type' })
  identifierType: string | null;

  @Expose({ name: 'phone_number' })
  phoneNumber: string;
}

export class GuestPassengerLiteModel {
  id: string;
  name: string;
  type: string;
  gender: string;

  @Type(() => Number)
  age: number;

  @Expose({ name: 'identifier_code' })
  identifierCode: string | null;

  @Expose({ name: 'identifier_type' })
  identifierType: string | null;

  @Expose({ name: 'phone_number' })
  phoneNumber: string | null;
}

export class GuestVehicleModel {
  id: string;
  content: string;
  expedition: string;

  @Expose({ name: 'vehicle_type' })
  @Type(() => VehicleTypeModel)
  vehicleType: VehicleTypeModel;

  @Expose({ name: 'identifier_code' })
  identifierCode: string | null;

  @Expose({ name: 'identifier_type' })
  identifierType: string | null;
}

export class GuestVehicleLiteModel {
  id: string;
  content: string;
  expedition: string;

  @Expose({ name: 'vehicle_type' })
  @Type(() => VehicleTypeModel)
  vehicleType: VehicleTypeModel;

  @Expose({ name: 'identifier_code' })
  identifierCode: string;

  @Expose({ name: 'identifier_type' })
  identifierType: string;
}

export class getGuestPassengersInput {
  params?: {
    q?: string;
  };
}

export class getGuestVehiclesInput {
  params?: {
    q?: string;
    filter?: string;
  };
}

export class getGuestPassengerInput {
  id: string;
}

export class getGuestVehicleInput {
  id: string;
}

export class GuestPassengerMutationInput {
  type: string;
  identifierCode: string | null;
  identifierType: string | null;
  name: string;
  age: number;
  gender: string;
  phoneNumber: string;
}

export class GuestPassengerMutationUpdate {
  id: string;
  body: GuestPassengerMutationInput;
}

export class GuestPassengerMutationDelete {
  id: string;
}

export class GuestVehicleMutationInput {
  vehicleTypeId: string;
  identifierType: string;
  identifierCode: string;
  content: string;
  expedition: string;
}

export class GuestVehicleMutationUpdate {
  id: string;
  body: GuestVehicleMutationInput;
}
export class GuestVehicleMutationDelete {
  id: string;
}
