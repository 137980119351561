/* eslint-disable react/no-unknown-property */
import { useRevoke } from 'api-hooks/auth/mutation';
import { useGetMe } from 'api-hooks/auth/query';
import { useReadNotification } from 'api-hooks/notification/mutation';
import {
  getNotificationsKey,
  useGetNotifications,
} from 'api-hooks/notification/query';
import {
  IndonesiaFlag,
  TicketIcon,
  UnitedKingdomFlag,
  UserCircleIcon,
} from 'assets/images/svg';
import { setLanguage } from 'common/language-storage';
import NavigationEnum from 'common/navigation';
import { queryClient } from 'common/repositories/query-client';
import logout from 'common/utils/auth';
import IconBox from 'components/commons/icon-box';
import { Popper } from 'components/commons/popper';
import Stack from 'components/commons/stack';
import Text from 'components/commons/text';
import Button from 'components/elements/button';
import useDialog from 'hooks/use-dialog';
import useGetMeQuery from 'hooks/use-get-me-query';
import isEmpty from 'lodash/isEmpty';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import * as Icons from 'react-feather';
import InfiniteScroller from 'react-infinite-scroller';
import { Oval } from 'react-loader-spinner';
import OutsideClickHandler from 'react-outside-click-handler';
import { CSS, VariantProps } from 'styles';
import color from 'styles/color';
import typography from 'styles/typography';
import { useDebouncedCallback } from 'use-debounce';

import CookiesDialog from './cookies-dialog';
import LogoutDialog from './logout-dialog';
import Notification from './notification';
import {
  StyledHeaderContainer,
  StyledHeader,
  StyledLeftSideContent,
  StyledRightSideContent,
  styles,
  AsideContainer,
  MobileMenuContainer,
  TopMenuContent,
  ProfileImageContainer,
  ProfileContainer,
  ALinkHeader,
  ALinkMenu,
  MenuContainer,
  MenuDesktopContainer,
  AsideNotificationContainer,
  NotificationHeader,
  MiniMenu,
} from './style';
import NavHeaderButton from '../nav/nav-header/button';

type HeaderProps = React.HTMLAttributes<HTMLElement>;
type CssProps = { css?: CSS };
type HeaderVariants = VariantProps<typeof StyledHeader>;

export type IHeaderProps = HeaderProps & CssProps & HeaderVariants;

const Header = React.forwardRef<HTMLElement, IHeaderProps>((props, ref) => {
  const { t } = useTranslation();
  const { mutate: revoke } = useRevoke();
  const { isAuthenticating: isLoading, isAuthenticated: isLogged } =
    useGetMeQuery();
  const [isOpen, setIsOpen] = React.useState({
    menu: false,
    notification: false,
  });

  const { locale, pathname, push, asPath } = useRouter();

  const dialog = useDialog();

  const enabled = React.useMemo(() => {
    try {
      return !!localStorage?.getItem('credential');
    } catch (e) {
      dialog.showCustom({
        render(close) {
          return (
            <CookiesDialog
              onClose={close}
              onConfirm={() => (window.location.href = '/')}
            />
          );
        },
      });
      return false;
    }
  }, [dialog]);

  const { data: meData, refetch } = useGetMe({
    enabled,
  });
  const hasMe = !isEmpty(meData);

  const removePrevUrl = React.useCallback(() => {
    try {
      sessionStorage?.removeItem('prevUrl');
    } catch (e) {
    } finally {
    }
  }, []);

  const onClickMenuItem = React.useCallback(
    (isMobile = false) =>
      () => {
        if (isMobile) {
          setIsOpen((prev) => {
            return {
              ...prev,
              menu: false,
            };
          });
        }
        removePrevUrl();
      },
    [removePrevUrl],
  );
  const menuList = React.useMemo<
    {
      icon: React.ReactNode;
      text: string;
      href: string;
      onClick: (isMobile?: boolean) => () => void;
    }[]
  >(
    () => [
      {
        icon: <Icons.Home size={24} color={color.typeGray} />,
        text: t('common:home'),
        href: `/${locale}`,
        onClick: onClickMenuItem,
      },
      {
        icon: <TicketIcon size={24} color={color.typeGray} />,
        text: t('common:check_booking'),
        href: `/${locale}/check-booking`,
        onClick: onClickMenuItem,
      },
      {
        icon: <Icons.Info size={24} color={color.typeGray} />,
        text: t('common:tnc'),
        href: `/${locale}/term-and-condition`,
        onClick: onClickMenuItem,
      },
      {
        icon: <Icons.Users size={24} color={color.typeGray} />,
        text: t('common:about_us'),
        href: `/${locale}/about-us`,
        onClick: onClickMenuItem,
      },
    ],
    [t, locale, onClickMenuItem],
  );

  const handleLogout = React.useCallback(async () => {
    try {
      await revoke();
      await localStorage.removeItem('credential');
      push(NavigationEnum.login);
      queryClient.removeQueries(['getMe']);
      refetch();

      await logout();
    } catch (e) {
    } finally {
    }
  }, [push, refetch, revoke]);

  const { data, hasNextPage, fetchNextPage } = useGetNotifications({
    keepPreviousData: true,
    enabled: hasMe,
  });
  const { mutateAsync } = useReadNotification({
    onSuccess: async () => {
      queryClient.refetchQueries(getNotificationsKey());
    },
  });

  const onReadNotification = React.useCallback(async () => {
    try {
      await mutateAsync();
    } catch (error) {}
  }, [mutateAsync]);

  const debounceNotification = useDebouncedCallback(fetchNextPage, 2000);

  const onClickLogout = React.useCallback(
    (toggle?: () => void, isMobile = false) =>
      () => {
        dialog.showCustom({
          render(close) {
            return (
              <LogoutDialog
                onClose={() => {
                  close();
                  if (isMobile) {
                    setIsOpen((prev) => ({
                      ...prev,
                      menu: true,
                    }));
                  }
                }}
                onConfirm={async () => {
                  try {
                    await handleLogout();
                    toggle?.();
                    if (isMobile) {
                      setIsOpen((prev) => ({
                        notification: false,
                        menu: false,
                      }));
                    }
                  } catch (error) {}
                }}
              />
            );
          },
        });
      },
    [dialog, handleLogout, setIsOpen],
  );

  const onClickReadNotification = React.useCallback(() => {
    setIsOpen((prev) => ({
      ...prev,
      notification: !prev.notification,
    }));

    onReadNotification();
  }, [onReadNotification]);

  const onClickOpenMenu = React.useCallback(() => {
    setIsOpen((prev) => ({ ...prev, menu: true }));
  }, [setIsOpen]);
  const notifications = React.useMemo(() => {
    const pages = data?.pages;
    if (!pages) return [];

    const result = pages
      .map((page) => {
        return page.data;
      })
      .flat();

    return result;
  }, [data?.pages]);

  const notificationItems = (
    <>
      {notifications.length ? (
        <>
          {notifications.map((notification) => {
            return <Notification data={notification} key={notification.id} />;
          })}
        </>
      ) : (
        <Text
          variant="body3"
          css={{ color: color.primary, margin: 'auto' }}
          align="center"
        >
          {t('common:empty_notification')}
        </Text>
      )}
    </>
  );

  const isLogin = pathname === NavigationEnum.login;
  const isRegister = pathname === NavigationEnum.register;
  const isForgotPassword = pathname === NavigationEnum.forgotPassword;
  const isIndonesiaLocale = locale === 'id';

  const indonesiaComponent = (
    <>
      <IndonesiaFlag className={styles.shadow()} />
      {t('common:indonesia')}
    </>
  );

  const englishComponent = (
    <>
      <UnitedKingdomFlag className={styles.shadow()} />
      {t('common:english')}
    </>
  );

  const languageComponent = isIndonesiaLocale
    ? indonesiaComponent
    : englishComponent;

  const linkStyles: React.CSSProperties = {
    textDecoration: 'none',
  };

  const onClickLangauge = React.useCallback(
    (language: 'id' | 'en') => () => {
      setLanguage(language);
    },
    [],
  );

  return (
    <>
      <style jsx global>{`
        body {
          overflow-y: ${isOpen.menu || isOpen.notification
            ? 'hidden !important'
            : 'scroll !important'};
        }
      `}</style>

      <StyledHeader {...props} ref={ref}>
        <StyledHeaderContainer>
          <StyledLeftSideContent>
            <div className={styles.logoBig()} onClick={removePrevUrl}>
              <ALinkHeader href={NavigationEnum.home} style={linkStyles}>
                <Image
                  src="/assets/WJL-logo-long.svg"
                  width={230}
                  height={23}
                  layout="intrinsic"
                  alt="logo-dashboard"
                />
              </ALinkHeader>
            </div>
            <div className={styles.logoSmall()} onClick={removePrevUrl}>
              <IconBox onClick={onClickOpenMenu} css={{ mr: 12 }}>
                <Icons.Menu size={24} color={color.primary} />
              </IconBox>

              <ALinkHeader
                href={NavigationEnum.home}
                onClick={removePrevUrl}
                style={linkStyles}
              >
                <Image
                  src="/assets/WJL-logo-long.svg"
                  width={170}
                  height={17}
                  layout="intrinsic"
                  alt="logo-dashboard"
                />
              </ALinkHeader>
            </div>
            {!isRegister && !isLogin && !isForgotPassword && (
              <MenuDesktopContainer>
                {menuList
                  .filter((el) => el.text !== t('common:home'))
                  .map((menu, idx) => (
                    <NavHeaderButton
                      key={menu.href + menu.text}
                      href={menu.href}
                      isActive={pathname === menu.href}
                      onClick={menu.onClick()}
                    >
                      {menu.text}
                    </NavHeaderButton>
                  ))}
              </MenuDesktopContainer>
            )}
          </StyledLeftSideContent>
          <StyledRightSideContent>
            <Popper
              css={{ display: 'none', '@md': { display: 'flex' } }}
              trigger={(ref, toggle) => (
                <Button
                  variant="tertiary"
                  css={{ 'svg:first-child': { mr: 8 } }}
                  onClick={toggle}
                  ref={ref}
                >
                  {languageComponent}
                  <Icons.ChevronDown />
                </Button>
              )}
            >
              {(toggle) => (
                <>
                  <Link
                    href={asPath}
                    passHref
                    locale="id"
                    style={linkStyles}
                    onClick={onClickLangauge('id')}
                  >
                    <MiniMenu
                      onClick={toggle}
                      css={{
                        color: '$primary',
                        ...typography.buttonMedium,
                        svg: { mr: 10 },
                        '&:hover': {
                          color: '$primary4',
                          backgroundColor: 'transparent',
                          opacity: 0.8,
                        },
                      }}
                    >
                      <IndonesiaFlag className={styles.shadow()} />
                      {t('common:indonesia')}
                    </MiniMenu>
                  </Link>
                  <Link
                    href={asPath}
                    passHref
                    locale="en"
                    style={linkStyles}
                    onClick={onClickLangauge('en')}
                  >
                    <MiniMenu
                      onClick={toggle}
                      css={{
                        color: '$primary',
                        ...typography.buttonMedium,
                        svg: { mr: 10 },
                        '&:hover': {
                          color: '$primary4',
                          backgroundColor: 'transparent',
                          opacity: 0.8,
                        },
                      }}
                    >
                      <UnitedKingdomFlag className={styles.shadow()} />
                      {t('common:english')}
                    </MiniMenu>
                  </Link>
                </>
              )}
            </Popper>
            {isLoading ? (
              <>
                <div
                  style={{
                    width: 150,
                    height: 50,
                    backgroundColor: '#c7c7c7',
                    borderRadius: 8,
                  }}
                />
              </>
            ) : (
              <>
                {isLogged ? (
                  <>
                    <Popper
                      trigger={(ref, toggle) => (
                        <IconBox
                          ref={ref}
                          onClick={() => {
                            toggle();
                            onReadNotification();
                          }}
                        >
                          <Icons.Bell color={color.primary} />
                        </IconBox>
                      )}
                      childContainerCss={{
                        minWidth: 200,
                        maxWidth: 400,
                        maxHeight: 400,
                        overflow: 'auto',
                      }}
                      css={{
                        display: 'none',
                        '@md': {
                          display: 'flex',
                        },
                      }}
                    >
                      {(toggle) => {
                        return (
                          <InfiniteScroller
                            loadMore={() => debounceNotification()}
                            hasMore={hasNextPage}
                            useWindow={false}
                            loader={
                              <Stack alignItems="center">
                                <Oval
                                  color={color.primary}
                                  secondaryColor={color.primary5}
                                  width={24}
                                  height={24}
                                />
                              </Stack>
                            }
                          >
                            {notificationItems}
                          </InfiniteScroller>
                        );
                      }}
                    </Popper>
                    <Popper
                      css={{ display: 'none', '@md': { display: 'flex' } }}
                      trigger={(ref, toggle) => (
                        <Button
                          variant="tertiary"
                          css={{ 'svg:first-child': { width: 40, height: 40 } }}
                          onClick={toggle}
                          ref={ref}
                        >
                          <UserCircleIcon size={40} /> <Icons.ChevronDown />
                        </Button>
                      )}
                    >
                      {(toggle) => (
                        <>
                          <Link
                            href={NavigationEnum.profile}
                            passHref
                            style={linkStyles}
                          >
                            <MiniMenu
                              onClick={toggle}
                              css={{
                                color: '$primary',
                                ...typography.buttonMedium,
                                svg: { mr: 10 },
                                '&:hover': {
                                  color: '$primary4',
                                  backgroundColor: 'transparent',
                                  opacity: 0.8,
                                },
                              }}
                            >
                              <Icons.User />
                              {t('common:account')}
                            </MiniMenu>
                          </Link>
                          <Button
                            variant="tertiary"
                            onClick={onClickLogout(toggle)}
                            css={{
                              svg: { mr: 8 },
                              color: '$primary',
                              width: '100%',
                            }}
                          >
                            <Icons.LogOut /> {t('common:logout')}
                          </Button>
                        </>
                      )}
                    </Popper>
                    <IconBox
                      onClick={onClickReadNotification}
                      css={{ display: 'display', '@md': { display: 'none' } }}
                    >
                      <Icons.Bell color={color.primary} />
                    </IconBox>
                  </>
                ) : (
                  <>
                    {!isLogin && (
                      <Link
                        href={NavigationEnum.login}
                        passHref
                        style={linkStyles}
                      >
                        <Button
                          variant="secondary"
                          css={{
                            minWidth: 80,
                            display: 'none',
                            '@sm': { display: 'flex' },
                            '@xl': { minWidth: 123 },
                          }}
                        >
                          {t('common:login')}
                        </Button>
                      </Link>
                    )}
                    {!isLogin && (
                      <IconBox
                        css={{ display: 'flex', '@sm': { display: 'none' } }}
                      >
                        <Link
                          href={NavigationEnum.login}
                          passHref
                          style={linkStyles}
                        >
                          <UserCircleIcon size={36} />
                        </Link>
                      </IconBox>
                    )}
                    {!isRegister && (
                      <Link
                        href={NavigationEnum.register}
                        passHref
                        style={linkStyles}
                      >
                        <Button
                          css={{
                            minWidth: 80,
                            display: 'none',
                            '@sm': { display: 'flex' },
                            '@xl': { minWidth: 123 },
                          }}
                        >
                          {t('common:register')}
                        </Button>
                      </Link>
                    )}
                  </>
                )}
              </>
            )}
          </StyledRightSideContent>
        </StyledHeaderContainer>
      </StyledHeader>
      {isOpen.notification && (
        <AsideNotificationContainer>
          <NotificationHeader>
            <IconBox
              css={{ mr: 12 }}
              onClick={() =>
                setIsOpen((prev) => ({ ...prev, notification: false }))
              }
            >
              <Icons.ChevronLeft />
            </IconBox>
            <Text variant="headline5">{t('common:notification')}</Text>
          </NotificationHeader>
          <InfiniteScroller
            hasMore={hasNextPage}
            loadMore={() => debounceNotification()}
            loader={
              <Stack alignItems="center">
                <Oval
                  color={color.primary}
                  secondaryColor={color.primary5}
                  width={24}
                  height={24}
                />
              </Stack>
            }
            useWindow={false}
          >
            {notificationItems}
          </InfiniteScroller>
        </AsideNotificationContainer>
      )}
      {isOpen.menu && (
        <AsideContainer>
          <OutsideClickHandler
            onOutsideClick={() =>
              setIsOpen((prev) => ({ ...prev, menu: false }))
            }
          >
            <MobileMenuContainer>
              <TopMenuContent>
                <div className={styles.closeContainer()}>
                  <IconBox
                    onClick={() =>
                      setIsOpen((prev) => ({ ...prev, menu: false }))
                    }
                  >
                    <Icons.X size={32} color={color.neutral8} />
                  </IconBox>
                </div>
                <ProfileImageContainer>
                  <UserCircleIcon />
                </ProfileImageContainer>
                <ProfileContainer>
                  {isLoading ? (
                    <div>
                      <div
                        style={{
                          height: 10,
                          width: 60,
                          backgroundColor: '#c7c7c7',
                          borderRadius: 2,
                        }}
                      />
                      <div
                        style={{
                          height: 40,
                          width: '100%',
                          backgroundColor: '#c7c7c7',
                          borderRadius: 4,
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <Text variant="headline4">
                        {isLogged ? meData?.data.name : t('common:user_name')}
                      </Text>
                      {isLogged ? (
                        <ALinkHeader
                          href={NavigationEnum.profile}
                          style={linkStyles}
                        >
                          {t('common:profile_detail')}
                          <Icons.ChevronRight size={16} color={color.primary} />
                        </ALinkHeader>
                      ) : (
                        <ALinkHeader
                          href={NavigationEnum.login}
                          style={linkStyles}
                        >
                          {t('common:click_to_login')}
                          <Icons.ChevronRight size={16} color={color.primary} />
                        </ALinkHeader>
                      )}
                      <Popper
                        css={{ display: 'flex', width: '100%' }}
                        trigger={(ref, toggle) => (
                          <Button
                            variant="tertiary"
                            css={{
                              'svg:first-child': { mr: 16 },
                              ...typography.buttonLarge,
                              backgroundColor: 'inherit',
                              padding: 0,
                              mt: 16,
                              width: '100%',
                              display: 'flex',
                              '@md': {
                                display: 'none',
                              },
                            }}
                            onClick={toggle}
                            ref={ref}
                          >
                            {languageComponent}
                            <Icons.ChevronDown />
                          </Button>
                        )}
                      >
                        {(toggle) => (
                          <>
                            <Link
                              href={asPath}
                              passHref
                              locale="id"
                              style={linkStyles}
                              onClick={onClickLangauge('id')}
                            >
                              <MiniMenu
                                onClick={toggle}
                                css={{
                                  color: '$primary',
                                  ...typography.buttonMedium,
                                  svg: { mr: 10 },
                                  '&:hover': {
                                    color: '$primary4',
                                    backgroundColor: 'transparent',
                                    opacity: 0.8,
                                  },
                                }}
                              >
                                {indonesiaComponent}
                              </MiniMenu>
                            </Link>
                            <Link
                              href={asPath}
                              passHref
                              locale="en"
                              style={linkStyles}
                              onClick={onClickLangauge('en')}
                            >
                              <MiniMenu
                                onClick={toggle}
                                css={{
                                  color: '$primary',
                                  ...typography.buttonMedium,
                                  svg: { mr: 10 },
                                  '&:hover': {
                                    color: '$primary4',
                                    backgroundColor: 'transparent',
                                    opacity: 0.8,
                                  },
                                }}
                              >
                                {englishComponent}
                              </MiniMenu>
                            </Link>
                          </>
                        )}
                      </Popper>
                    </>
                  )}
                </ProfileContainer>
              </TopMenuContent>
              <MenuContainer>
                {menuList.map((item) => (
                  <ALinkMenu
                    key={item.href}
                    href={item.href}
                    onClick={item.onClick(true)}
                  >
                    {item.icon} {item.text}
                  </ALinkMenu>
                ))}
              </MenuContainer>
              {isLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: 40,
                    backgroundColor: '#c7c7c7',
                    borderRadius: 4,
                  }}
                />
              ) : (
                <>
                  {isLogged && (
                    <MiniMenu onClick={onClickLogout(undefined, true)}>
                      <Icons.LogOut color={color.typeGray} />{' '}
                      {t('common:logout')}
                    </MiniMenu>
                  )}
                </>
              )}
            </MobileMenuContainer>
          </OutsideClickHandler>
        </AsideContainer>
      )}
    </>
  );
});

Header.displayName = 'Header';

export default Header;
